@font-face {
  font-family: 'Ekomatik-Arial-Black';
  src: url('/src/assets/fonts/Arial-Black.ttf');
  font-weight: 700;
  font-style: normal; 
}

body {
  margin: 0;
  padding: 0;
  font-family: Ekomatik-Arial-Black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

}

a {
  text-decoration: none;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

ul {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
