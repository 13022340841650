.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.input-wrapper {
    font-size: 20px;
    display: flex;
}

.label {
    width: 120px
}

.button {
    height: 60px;
    width: 100%;
    margin-top: 20px;
    background-color: #00999B;
    color: #fff;
    font-size: 18px;
    cursor: pointer
}

.button-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    position: absolute;
}

.alert {
    position: absolute;
    font-size: 14px;
}