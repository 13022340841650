@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @components responsive {
      .text-unset {
        text-align: unset;
      }
    }
  }